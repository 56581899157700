$tooltip-text-color: #fff;
$tooltip-background-color: #5D6575;

$shadow-color: rgba(5,43,81,.1);

$popup-background-color: #fff;

$popup-header-border-color: #e0e0e0;

$trigger-size: 70px;
$trigger-offset: 30px;

.kustomer-feedback-component {
    user-select: none;
    position: fixed;
    bottom: $trigger-offset;
    right: $trigger-offset;
    width: $trigger-size;
    height: $trigger-size;
    padding: 10px;
    &:not(.is-open):hover {
        .kustomer-tooltip {
            opacity: 1;
            transform: translateX(-5px);
            transition: opacity .5s ease-out, transform .5s ease-out;
            visibility: visible;
        }
    }
    &.is-open {
        .kustomer-popup {
            opacity: 1;
            top: 0px;
            transform: translateY(-100%);
        }
    }
    .kustomer-tooltip {
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        font-size: .8em;
        right: 70px;
        top: 25px;
        line-height: 1em;
        background-color: $tooltip-background-color;
        color: $tooltip-text-color;
        letter-spacing: .1em;
        border-radius: 1em;
        padding: .55em 1em;
    }
    .kustomer-trigger {
        cursor: pointer;
        border-radius: 1000px;
        width: 50px;
        height: 50px;
        padding: 10px;
        box-shadow: 0 2px 8px $shadow-color;
        img {
            max-width: 100%;
        }
        &.is-kustomer-trigger-spinning {
            animation: spin .5s ease-in-out;
        }
        &.is-kustomer-trigger-spinning-reverse {
            animation: spin-reverse .5s ease-in-out;
        }
    }
    .kustomer-popup {
        transition: opacity .5s ease-out, transform .5s ease-out;
        position: absolute;
        box-shadow: 0 2px 8px $shadow-color;
        opacity: 0;
        background-color: $popup-background-color;
        width: 350px;
        min-height: 400px;
        top: 80px;
        right: 10px;
        border-radius: 10px;
        overflow: hidden;
        .kustomer-header {
            padding: 30px 0;
            border-bottom: 1px solid $popup-header-border-color;
            .kustomer-logo {
                height: 60px;
                text-align: center;
                margin: 0 auto;
                width: 300px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            h1 {
                margin-top: 30px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: .02em;
                padding: 0 30px;
                white-space: nowrap;
                text-align: center;
                margin-bottom: 0;
            }
        }
        .kustomer-container {
            position: relative;
            section.kustomer-feedbacks {
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin: 0;
                padding: 20px 0;
                .kustomer-feedback {
                    padding: 20px 40px;
                    cursor: pointer;
                    transition: background-color .3s ease-out;
                    align-items: center;
                    display: flex;
                    img {
                        width: 25px;
                        max-height: 25px;
                        display: inline-block;
                        margin-right: 15px;
                    }
                    p {
                        font-size: 16px;
                        padding-left: 15px;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                    }
                    &:hover {
                        background-color: hsl(210, 9%, 96%);
                    }
                }
            }
            section.kustomer-form {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                transform: translateX(100%);
                transition: transform .3s ease-out;
                background-color: $popup-background-color;
                &.is-open {
                    transform: translateX(0);
                }
                .kustomer-back {
                    height: 45px;
                    width: 100%;
                    padding: 10px;
                    cursor: pointer;
                    transition: background-color .3s ease-out;
                    &:hover {
                        background-color: hsl(210, 9%, 96%);
                    }
                    img {
                        max-height: 100%;
                        width: 25px;
                    }
                }
                h2 {
                    width: 270px;
                    margin: 0 auto;
                    font-size: 15px;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
                form {
                    width: 270px;
                    margin: 0 auto;
                    text-align: right;
                    textarea {
                        color: #808080;
                        width: 100%;
                        resize: none;
                        outline: none;
                        border: 0;
                        padding: 0 10px 0 0;
                        background-color: transparent;
                        overflow: hidden;
                        border-bottom-style: solid;
                        border-bottom-width: 3px;
                        margin-bottom: 30px;
                    }
                    button {
                        color: #fff;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        padding-top: .5rem;
                        padding-bottom: .5rem;
                        font-weight: 700;
                        border-radius: .25rem;
                        cursor: pointer;
                        background: transparent;
                        -webkit-appearance: button;
                        text-transform: none;
                        overflow: visible;
                        font-family: inherit;
                        font-size: 16px;
                        line-height: 1.15;
                        margin: 0;
                        border: 0 solid #fff;
                        position: relative;
                        &.is-loading {
                            box-shadow: none;
                            cursor: not-allowed;
                            opacity: .5;
                            color: transparent !important;
                            pointer-events: none;
                            &::after {
                                border-color: transparent transparent #fff #fff !important;
                                left: calc(50% - (1em / 2));
                                top: calc(50% - (1em / 2));
                                position: absolute !important;
                                animation: loading .5s infinite linear;
                                border: 2px solid #dbdbdb;
                                border-radius: 290486px;
                                content: "";
                                display: block;
                                height: 1em;
                                width: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Icon Rotation
@keyframes spin {
    100% {
        transform:rotate(720deg);
    }
}
@keyframes spin-reverse {
    100% {
        transform:rotate(-720deg);
    }
}

@keyframes loading{
    from{
        transform:rotate(0);
    }
    to{
        transform:rotate(359deg);
    }
}
