@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400%3b700&display=swap");

$font-primary:
  Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-secondary: $font-primary;
$font-size-base:              0.875rem;


$font-tertiary: 'Roboto Slab', serif;
$font-fourtiary: 'Roboto Condensed', serif;
$font-fiftiary: 'Roboto Black', serif;


@font-face {
    font-family: 'Roboto Slab';
    src: local('Roboto Slab Regular'), local('RobotoSlabRegular'),
        url('../static/fonts/RobotoSlab-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: local('Roboto Slab Bold'), local('RobotoSlabBold'),
        url('../static/fonts/RobotoSlab-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Black';
    src: local('Roboto Black'), local('RobotoBlack'),
        url('../static/fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Bold'), local('RobotoCondensedBold'),
        url('../static/fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Regular'), local('RobotoCondensedRegular'),
        url('../static/fonts/RobotoCondensed-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed Light'), local('RobotoCondensedLight'),
        url('../static/fonts/RobotoCondensed-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
